@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;700&display=swap");
.css-2koi0q-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

*{
 font-family:"Josefin Sans"
}
.css-3e6mms-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.css-1k8eflz-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiListItem-root{
    font-family:"Josefin Sans"!important
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.swiper-slide {
  display: flex !important;
  justify-content: center !important;
}

.swiper-vertical {
  height: "100%" !important;
  touch-action: pan-x;
}

@media (max-width: 767px){
  .swiper{
    width: 100%;
    height: 100%;
  }
  
}
